





























































































import Vue from "vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {Collection, ImageVersion} from "@/store/types";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "StoriesTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    showDeleteDialog: false,
    collToDelete: null as unknown | Collection,
    headers: [
      {
        text: "Imagem",
        value: "image",
        sortable: false,
        width: "100"
      },
      {
        text: "Expiração",
        value: "expiring_at"
      },
      {
        text: "Coleção",
        value: "collection_id"
      },
      {
        text: "Visualizações",
        value: "views"
      },
      {
        text: "Informações",
        value: "info",
        align: "center",
        width: "200",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  computed: {
    ...mapGetters({
      collections: "collections/getCollections"
    }),
  },

  methods: {
    showDelDialog(item: Collection): void {
      this.showDeleteDialog = true
      this.collToDelete = item;
    },

    onEdit(item: Collection): void {
      this.$emit("onEdit", item)
    },

    onDelete(): void {
      this.$emit("onDelete", this.collToDelete)
      this.showDeleteDialog = false
    },

    getCollectionName(collId: string): string {
      if (this.collections.length) {
        const [collection] = this.collections.filter((coll: Collection) => coll.id === collId)
        return collection?.name ?? ""
      }
      return ""
    },

    extractImage(images: Array<ImageVersion>): string {
      return images[0]?.url ?? ""
    }
  }
})
