
























































































import Vue from "vue"
import { mapGetters } from "vuex";
import { Story, ImageUpload, mediaType, ImgDimensions } from "@/store/types";
import { v4 as uuidv4 } from "uuid";

export default Vue.extend({
  name: "StoriesForm",

  props: {
    story: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      daysToExpire: [
        { text: "1", value: 1 },
        { text: "3", value: 3 },
        { text: "7", value: 7 },
        { text: "15", value: 15 },
        { text: "30", value: 30 },
      ],
      payload: {
        id: "",
        collection_id: "",
        media_type: mediaType.Image,
        story_duration_secs: 5,
        expiring_at: new Date().setDate(new Date().getDate() + 1),
        expiring_days: 1,
        disable_share: true,
        disable_likes: true,
        disable_comments: true,
        is_paid_partnership: false,
        has_product: false,
        has_link: false,
        has_video: false,
        user: "",
        product: null,
        link: "",
        image_versions: [],
        original_width: 0,
        original_height: 0,
        video_codec: "",
        video_versions: [],
        created_at: new Date(),
        updated_at: new Date(),
      } as Story
    };
  },

  computed: {
    ...mapGetters({
      collections: "collections/getCollections",
      userId: "auth/getUid"
    }),

    // FIXME, por enquanto, vamos mostrar só uma das versões
    storyImage(): string {
      const placeholder = "https://i.imgur.com/tV3k15O.jpg"
      return this.payload.image_versions[0]?.url ?? placeholder
    }
  },

  mounted() {
    this.$store.dispatch("collections/fetch");
    if (this.story) {
      Object.assign(this.payload, this.story)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      const today = new Date()
      this.payload.updated_at = new Date()
      this.payload.expiring_at = today.setDate(today.getDate() + this.payload.expiring_days)
      this.payload.user = this.userId

      this.payload.has_link = !!this.payload.link // FIXME pensar numa implementação melhor

      if (this.story) {
        this.$store.dispatch("stories/update", this.payload);
      } else {
        this.$store.dispatch("stories/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      if (!file.type.includes("image")) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target, ...rest }) => {
        const image: ImageUpload = {
          folder: "/stories/images",
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        const imgDimensions = await this.getImageDimensions(file)

        this.$store.dispatch("uploader/uploadImage", image)
            .then(url => {
              this.payload.original_height = imgDimensions.height
              this.payload.original_width = imgDimensions.width
              this.payload.image_versions.push({
                url,
                width: imgDimensions.width,
                height: imgDimensions.height
              })
            })
      };
      reader.readAsArrayBuffer(file);
    },

    getImageDimensions(file: Blob): Promise<ImgDimensions> {
      return new Promise((resolve) => {
        const _URL = window.URL || window.webkitURL;

        const img = new Image()

        img.onload = function() {
          const { width, height } = this as HTMLImageElement
          resolve({ width, height })
        };

        img.src = _URL.createObjectURL(file)
      })
    }
  }
})
