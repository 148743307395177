

























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import StoriesForm from "./components/StoriesForm.vue"
import StoriesTable from "./components/StoriesTable.vue"
import { Story } from "@/store/types";

export default Vue.extend({
  name: "Stories",
  
  components: {
    FullScreenDialog,
    StoriesForm,
    StoriesTable
  },

  data: () => ({
    fullScreenDialog: false,
    story: null as unknown | Story
  }),

  computed: {
    ...mapGetters({
      stories: "stories/getStories",
    })
  },

  mounted() {
    this.$store.dispatch("stories/fetch");
  },

  methods: {
    editCol(story: Story): void {
      this.story = story;
      this.openFullScreenDialog();
    },

    deleteCol(story: Story): void {
      this.$store.dispatch("stories/delete", story);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.story = null;
    },
  }
})
