var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stories-table"},[_c('ConfirmDialog',{attrs:{"title":"Deseja apagar a coleção?","text":"Essa ação não pode ser revertida!","show":_vm.showDeleteDialog},on:{"close":function($event){_vm.showDeleteDialog = false},"confirm":_vm.onDelete}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":_vm.extractImage(item.image_versions),"aspect-ratio":"1"}})]}},{key:"item.expiring_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.expiring_at).toUTCString())+" ")]}},{key:"item.collection_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCollectionName(item.collection_id))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"m-small",staticStyle:{"text-decoration":"none"},attrs:{"href":item.link,"target":"_blank","rel":"noopener noreferrer"}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Link")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_share ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-share")])],1)]}}],null,true)},[_c('span',[_vm._v("Compartilhamento "+_vm._s(item.disable_share ? 'Desativado' : 'Ativado'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_likes ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-thumb-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Curtidas "+_vm._s(item.disable_likes ? 'Desativadas' : 'Ativadas'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_comments ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Comentários "+_vm._s(item.disable_comments ? 'Desativados' : 'Ativados'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.is_paid_partnership ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_paid_partnership ? 'É uma' : 'Não é uma')+" Parceria Paga")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDelDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }